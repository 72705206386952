// require("normalize.css");
// require("../scss/style.scss");

// import ""
import 'vite/modulepreload-polyfill'
import "../scss/style.scss"

import "./general"
import "./contact"
import "./slide"

document.addEventListener("DOMContentLoaded",()=>{
    if (document.getElementById("preisrechner")){
        import("./preise")
    }
    if (document.getElementById("map")){
        console.log("leaflet")
        import("./leaflet")
    }
    import ("./photoswipe")

})
// console.log("something")
