import Splide from "@splidejs/splide";

document.addEventListener('DOMContentLoaded', function () {
    if (document.getElementsByClassName("splide").length === 0) {
        return
    }
    new Splide('.splide', {
            type: 'loop',
            autoplay: true,
            lazyLoad: 'nearby',
        }
    ).mount();

});
