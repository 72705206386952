function elementValue(id: string) {
    const element = document.getElementById(id) as HTMLInputElement
    return element.value
}

document.addEventListener("DOMContentLoaded", function () {
    const contactEl: HTMLElement = document.getElementById("contact")!
    const titleEl: HTMLElement = contactEl.querySelector(".title")!
    const nameEl: HTMLElement = contactEl.querySelector("#cName")!
    const formEl = document.getElementById("contactUsForm") as HTMLFormElement
    const label = document.getElementById("cLabel")!
    const peopleEl = document.getElementById("cPeople") as HTMLInputElement
    titleEl.addEventListener("click", function (event) {
        const open = contactEl.classList.contains("active")
        if (!open) {
            setTimeout(function () {
                nameEl.focus()
                contactEl.style.overflow = "auto"
            }, 600)
        } else {
            contactEl.style.overflow = "hidden"
        }
        contactEl.classList.toggle("active")
    })
    formEl.addEventListener("submit", function (event) {
        console.log("submit")
        event.preventDefault()
        const people = peopleEl.value
        if (people.length === 0) {
            label.innerText = "Bitte eine Zahl eintragen."
            label.style.display = "block"
            label.classList.add("label-warning")
            label.classList.remove("label-success")
            return false
        }
        const fields = document.querySelectorAll("#contact input, #contact textarea")
        fields.forEach(f => {
            f.setAttribute('disabled', "disabled")
        })
        const values = {
            "cName": elementValue("cName"),
            "cPeople": people,
            "cAnreise": elementValue("cAnreise"),
            "cAbreise": elementValue("cAbreise"),
            "cEmail": elementValue("cEmail"),
            "cPhone": elementValue("cPhone"),
            "cNachricht": elementValue("cNachricht"),
            "ajax": "true"
        }
        const formData = new FormData()
        Object.entries(values).forEach(
            ([key, value]) => formData.append(key, (value))
        )
        fetch("/contact.php", {
            method: "POST",
            body: formData
        }).then((response => {
            console.info(response.ok)
            if (!response.ok) {
                return Promise.reject(response)
            }
            return response.text()
        })).then((responseText) => {
            label.innerText = responseText

            label.innerText = "Die Anfrage wurde abgeschickt!"
            label.style.display = "block"
            label.classList.add("label-success")
            label.classList.remove("label-warning")
        }).catch((response) => {
            console.log("error")
            fields.forEach(f => {
                f.removeAttribute('disabled')
            })
            response.text().then((t: string) => {
                label.innerText = t
            })

            label.style.display = "block"
            label.classList.add("label-warning")
            label.classList.remove("label-success")
        })
        return false
    })
})

export {}
