const el: HTMLElement = document.querySelector("#navbar ul")!
const navButton = document.querySelector("nav svg")!

navButton.addEventListener("click", () => {
    if (el.style.display == 'flex')
        el.style.display = 'none';
    else
        el.style.display = 'flex';
    navButton.classList.toggle("active")
})

export {}
